import React from "react";
import { FrameStyle, Logo, ConentFrame } from "./App.styles";

function App() {
  return (
    <FrameStyle>
      <ConentFrame>
        <Logo />
      </ConentFrame>
    </FrameStyle>
  );
}
export default App;
